import React, {useEffect, useState} from "react";
import './Header.css';
import image1 from '../images/1.png';
import Video from '../images/video.mov';
import Homelane from '../images/homelane.png';


function Header() {

//verify if page is top or not
const [heightBack, setHeightBack] = useState(180);
const [size, setSize] = useState(70); // Tamanho inicial do vídeo
const [height, setHeight] = useState(100); // Altura inicial do vídeo
const [rightPos, setRightPos] = useState(0); // Posição inicial à direita
const [showtext, setShowText] = useState(false); // Posição inicial à direita
const [heightHomelane, setHeightHomelane] = useState(180);

// verify screen width
const [screenWidth, setScreenWidth] = useState(0);
const [screenHeight, setScreenHeight] = useState(0);

// useEffect(() => {
//     console.log(window.innerWidth, window.innerHeight)
//     setScreenWidth(window.innerWidth);
//     setScreenHeight(window.innerHeight);
//     window.addEventListener('resize', () => {
//         setScreenWidth(window.innerWidth);
//         setScreenHeight(window.innerHeight);
//     });
// }, [
//     // apenas quando a página carregar

// ]);

useEffect(() => {
    // Função para atualizar os estados
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
    };

    // Adicionando o event listener
    window.addEventListener('resize', handleResize);

    // Log inicial das dimensões
    console.log(screenWidth, screenHeight);
    console.log(window.innerHeight, window.innerWidth)

    // Função de limpeza que remove o event listener
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);


const changeHeight = () => {
    if(window.innerWidth > 768){
    if (window.scrollY <= 380) {
        // console.log('deveria acontecer')
        // console.log(screenWidth, screenHeight)
        setHeight(100); // Altura inicial
        setShowText(false); // Mostra o texto
        setHeightBack(180)
        setHeightHomelane(180)
        setSize(70); // Tamanho inicial
        setRightPos(0)
    } else if (window.scrollY > 380) {
        setHeightBack(window.scrollY * 0.05 + 180);
        setHeightHomelane(window.scrollY * 0.05 + 180);
        setSize(50); // Diminui o tamanho
        setHeight(85); // Diminui a altura
        setRightPos(20); // Move para a direita
        setShowText(true); // Mostra o texto
        // Note que você pode precisar ajustar a lógica aqui para obter o efeito desejado
    }
} else {
    if (window.scrollY <= 380) {
        // console.log('não deveria acontecer')
        // console.log(screenWidth, screenHeight)
        setHeight(100); // Altura inicia
        setShowText(false); // Mostra o texto
        setHeightBack(100)
        setHeightHomelane(280)
        setSize(70); // Tamanho inicial
        setRightPos(0)
    } else if (window.scrollY > 380) {
        // setHeightBack(window.scrollY * 0.05 + 180);
        setHeightHomelane(-300);
        setSize(100); // Diminui o tamanho
        setHeight(100); // Diminui a altura
        setRightPos(0); // Move para a direita
        setShowText(true); // Mostra o texto
        // Note que você pode precisar ajustar a lógica aqui para obter o efeito desejado
    }
}
};


useEffect(() => {
    window.addEventListener('scroll', changeHeight);
}
, []);

    return (<>
        <div class="hero-section">
        <div class="hero-content">
            <h1>Try new finishes on your room
</h1>
            <h1>With 3 clicks in seconds</h1>

            <p>Empower your sales with real-time visualization</p>
            {/* <button class="demo-button">Request a demo</button> */}
        </div>
  
    </div>
          {/* <div className="herobackground" 
          //className={`herobackground ${trans ? 'transition' : ''}`}


          style={{  top: `${heightBack}px` }}> */}
          {/* <img src={image1} alt="hero"  /> */}
          <div className="herobackground" style={{ top: `${heightBack}px` }}>
                <video autoPlay loop muted playsInline
  preload="auto" style={{ width: `${size}%`, marginLeft: `${rightPos}vw` , height: `calc(${height}% - 400px)`,     borderRadius: screenWidth < 768 ? '0' : '10px'}}>          <source src={Video} type='video/mp4' />
        </video>
          </div>
          <div 
          className={`homelane ${showtext ? 'show': null}`} style={{top: `${heightHomelane} + 300 px`}}
          >
            Adopted by <br/>
            <img src={Homelane} alt="hero"  />
          </div>
          </>
    );
}

export default Header;

