import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Certifique-se de importar o arquivo CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Logo from '../images/LOGO.png';

const Navbar = () => {
const [navColor, setNavColor] = React.useState(false);
const [showPopup, setShowPopup] = React.useState(false); // Estado para controlar a visibilidade do pop-up
    const [formData, setFormData] = React.useState({ // Estado para armazenar os dados do formulário
        name: '',
        email: '',
        website: '',
        profession: '',
        useCase: ''
    });

//verify if page is top or not
const changeNavColor = () => {
    if(window.scrollY >= 10){
        setNavColor(true);
    } else {
        setNavColor(false);
    }
}

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
};

useEffect(() => {
    window.addEventListener('scroll', changeNavColor);
}
, []);

const handleSubmit = async (e) => {
    e.preventDefault();
    const body = JSON.stringify({ EmailID: formData.email,
        Website: formData.website,
        Profession: formData.profession,
        Name: formData.name,
        Info: formData.useCase});
    try {
        console.log(body)
        const response = await fetch('https://3b4diuqokkjx2tvgwfvfkteigm0vxmqu.lambda-url.us-east-1.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        });

        if (response.ok) {
            // Tratar sucesso
            setShowPopup(false); // Fecha o pop-up
            // Resetar o formulário ou mostrar uma mensagem de sucesso
        } else {
            // Tratar erro
            console.error('Falha ao enviar o formulário.');
        }
    } catch (error) {
        console.error('Erro ao enviar o formulário: ', error);
    }
};

    return (
        <nav 
        // navbar or navbar2 is the class that will change the color of the navbar
{...navColor ? {className: 'navbar2'} : {className: 'navbar'}}
                >
            <div className="navbar-container">
                <div className="sanduiche">
                <div className="menu-icon">
                    {/* <FontAwesomeIcon icon={faBars} /> */}
                    <img src={Logo} alt="Logo" />
                </div>
                </div>


                <div className="menu-icon">
                <Link to="/" className="navbar-logo">
                    Instant Finish
                </Link> </div>
                <div className="menu">
                {/* Outros links do navbar aqui */}
                <Link to="#" onClick={() => setShowPopup(true)} className="nav-link nav-link-special">Request Demo</Link>
                </div>
            </div>
            {showPopup && (
            <div className="popup">
                
                <form onSubmit={handleSubmit}>
                    <h2>Request a demo</h2>
                    <input type="text" name="name" value={formData.name} onChange={handleInputChange} placeholder="Name" />
                    <input type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Email" />
                    <input type="text" name="website" value={formData.website} onChange={handleInputChange} placeholder="Website" />
                    <select name="profession" value={formData.profession} onChange={handleInputChange}>
                        <option value="manufacturer">Manufacturer</option>
                        <option value="designer">Designer</option>
                        <option value="supplier">Supplier</option>
                        <option value="ecommerce">Ecommerce</option>
                        <option value="other">Other</option>
                    </select>
                    <textarea name="useCase" value={formData.useCase} onChange={handleInputChange} placeholder="Tell us about your use case"></textarea>
                    <button type="submit">SUBMIT</button>
                </form>
                <button onClick={() => setShowPopup(false)}>CLOSE</button>
            </div>
        )}
        </nav>
    
    );
}

export default Navbar;