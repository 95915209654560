import React from "react";
import './Footer.css';
// import instagram, linkedin, twitter from fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';


function Footer() {
    return (
        <div className="Footer">
            <div className="footer-content">
                <div className="footer-logo">
                    <h1>powered by <a href="https://www.3dguru.ai" target="_blank">3DGuru.AI</a></h1>
                </div>
                   <a 
                //    send email to 3DGuru
                     href="mailto:
                     contact@3dguru.ai">contact@3dguru.ai</a>
                   
                <div className="footer-social">
                    {/* <h2>Social</h2> */}
                  {/* instagram link */}
                    <a href="https://www.instagram.com/3dguru.ai/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    {/* linkedin link */}
                    <a href="https://www.linkedin.com/company/3dguruai/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    {/* twitter link */}
                    <a href="https://twitter.com/3dguru_ai" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;