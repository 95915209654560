import React, {useEffect, useState, useRef} from "react";
import './Bridge.css';

function useOnScreen(options) {
    const ref = useRef();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [options]); // Dependências devem incluir apenas 'options'

    return [ref, visible];
}


function Bridge3() {

        //marginTop is the variable that will change the margin-top of the hero background
        const [marginTop, setMarginTop] = useState(700); // Inicia em 1000
        const [hasChanged, setHasChanged] = useState(false); // Novo estado para rastrear a mudança
        const [setRef, visible] = useOnScreen({ threshold: 0.1 });


    


        // Função para verificar o scroll e ajustar marginTop
        const changeMarginTop = () => {
            // Verifica se o scroll ultrapassou window.innerHeight + 50 e ainda não mudou
            if (window.scrollY > window.innerHeight + 50 && !hasChanged) {
                setMarginTop(0); // Muda marginTop para 0
                setHasChanged(true); // Marca que a mudança ocorreu
            }
            // Adiciona condição para verificar se o scroll está próximo do topo
            else if (window.scrollY <= 5 && hasChanged) { // Considera "próximo de 0" como sendo <= 5
                setMarginTop(1000); // Muda marginTop de volta para 1000
                setHasChanged(false); // Reseta hasChanged
            }
            // Se hasChanged é true, não faz mais nada, mantendo as mudanças anteriores
        };
        
    
        // useEffect(() => {
        //     window.addEventListener('scroll', changeMarginTop);
    
        //     // Limpeza do evento
        //     return () => window.removeEventListener('scroll', changeMarginTop);
        // }, [hasChanged]); 
        
    return (
        <div className="bridge" style={{marginTop: `${marginTop}px`}}>
            <div className={`box ${visible ? 'slide-up-enter' : ''}`} ref={setRef}>
            <div className="bridge_content">
                <h1>Step 1</h1>
                <p>Upload image</p>
                {/* <button class="demo-button">Request a demo</button> */}

            </div>
            <div className="bridge_content">
                <h1>Step 2</h1>
                <p>Click on a surface to transform</p>
                {/* <button class="demo-button">Request a demo</button> */}

            </div>
            <div className="bridge_content">
                <h1>Step 3</h1>
                <p>Choose texture to apply</p>
                {/* <button class="demo-button">Request a demo</button> */}

            </div>
            </div>
        </div>
    );

}

export default Bridge3;