import React , {useState, useEffect, useRef} from "react";
import './Features.css';
import Walls from '../images/walls.png';
import Sala1 from '../images/sala1.png';
import Sala2 from '../images/sala2.png';
import Sala3 from '../images/sala3.png';
import Sala4 from '../images/sala5.png';
import Sala0 from '../images/sala0.png';

function useOnScreen(options) {
    const ref = useRef();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [options]); // Dependências devem incluir apenas 'options'

    return [ref, visible];
}

function Features() {
    const [ref1, visible1] = useOnScreen({ threshold: 0.1 });
    const [ref2, visible2] = useOnScreen({ threshold: 0.1 });
    const [ref3, visible3] = useOnScreen({ threshold: 0.1 });
    const [ref4, visible4] = useOnScreen({ threshold: 0.1 });
    const [index, setIndex] = useState(0);
    const [images, setImages] = useState([]);


    // const images = [Sala0, Sala1, Sala2, Sala3, Sala4];

    useEffect(() => {
        const imgs = [Sala0, Sala1, Sala2, Sala3, Sala4].map(src => {
            const img = new Image();
            img.src = src;
            return img.src;
        });
        setImages(imgs);
    }, []);

    const handleClick = (index) => () => setIndex(index);
    const handleMouseLeave = () => setIndex(0);

    return (
        <div className="Features">
        
        <div className={`" " ${visible1 ? 'slide-up-enter' : ''}`} ref={ref1}> 
            <h1>Your materials. Our system.</h1><p>Confidence in shopping is just the beginning. Our AI algorithms enhance every aspect of consumer decision-making, <br/>translating into increased sales and loyalty.</p> 
        </div>
        
        <div className="demos">
            <div 
            // className={`" " ${visible2 ? 'slide-up-enter' : ''}`} ref={ref2}
            >
                <h2>AI identified surfaces</h2>
                <p onClick={handleClick(3)} >Walls</p>
                <p onClick={handleClick(1)}  >Floors</p>
                <p onClick={handleClick(2)} >Woodwork</p>
            </div>
            <div  
            // ref={ref3}
            >
                <img src={images[index]}/>
            </div>
        </div>
        <div className={`" " ${visible4 ? 'slide-up-enter' : ''}`} ref={ref4}>
        <h2 className="tailored">Tailored Solutions for Every Business Need</h2>
        </div>
        
        </div>
    );
    }

export default Features;
