import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom'; // Importe BrowserRouter

import Header from './components/Header.js';
import Navbar from './components/Navbar.js';
import Bridge from './components/Bridge.js';
import Features from './components/Features.js';
import Bridge2 from './components/Bridge2.js';
import Bridge3 from './components/Bridge3';
import Footer from './components/Footer.js';


function App() {
  return (
    <div className="App">
        <BrowserRouter> {/* Envolve o aplicativo com BrowserRouter */}
      <div className="App">
        <Navbar />
  <Header />
  
  <Bridge3 />
      <Features />
      {/* <Bridge2 />  */}
      <Footer />
            </div>
    </BrowserRouter>
   
  

    </div>
  );
}

export default App;
