import React, {useEffect, useState} from "react";
import './Bridge.css';

function Bridge() {

        //marginTop is the variable that will change the margin-top of the hero background
        const [marginTop, setMarginTop] = useState(1000); // Inicia em 1000
        const [hasChanged, setHasChanged] = useState(false); // Novo estado para rastrear a mudança
    
        // Função para verificar o scroll e ajustar marginTop
        const changeMarginTop = () => {
            // Verifica se o scroll ultrapassou window.innerHeight + 50 e ainda não mudou
            if (window.scrollY > window.innerHeight + 50 && !hasChanged) {
                // setMarginTop(0); // Muda marginTop para 0
                setHasChanged(false); // Marca que a mudança ocorreu
            }
            // Adiciona condição para verificar se o scroll está próximo do topo
            else if (window.scrollY <= 5 && hasChanged) { // Considera "próximo de 0" como sendo <= 5
                setMarginTop(1000); // Muda marginTop de volta para 1000
                setHasChanged(false); // Reseta hasChanged
            }
            // Se hasChanged é true, não faz mais nada, mantendo as mudanças anteriores
        };
        
    
        // useEffect(() => {
        //     window.addEventListener('scroll', changeMarginTop);
    
        //     // Limpeza do evento
        //     return () => window.removeEventListener('scroll', changeMarginTop);
        // }, [hasChanged]); 
        
    return (
        <div className="bridge" style={{marginTop: `${marginTop}px`}}>
            <div className="bridge_head">
    Shopping Redefined: Smarter, Faster, Affordable

            </div>
            <div className="bridge_content">
                <p>Welcome to <bold>TexturAI</bold>. We redefine home decor visualization using artificial intelligence. Our platform offers a vast catalog of products, allowing customers to accurately visualize them in their own spaces. This not only simplifies shopping but also makes it smarter and more affordable. Our solutions cater to manufacturers, retailers, and distributors, enhancing both digital and in-store experiences and facilitating decision-making.</p>
                <button className="demo-button">Request a demo</button>

            </div>
        </div>
    );

}

export default Bridge;